<template>
  <div class="row">
    <div class="flex md12">
      <template>
        <p>
          <va-modal
            v-model="showModal"
            :hide-default-actions="true"
            title="Editing Language"
            fixed-layout
          >
            <slot>
              <div
                class="modal"
                v-if="modalData"
              >
                <div class="row">
                  <div class="flex xs8">
                    <h3 class="underline">
                      {{ modalData.key }}
                    </h3>
                  </div>
                  <div class="flex xs4">
                    <va-toggle
                      v-model="richText"
                      label="HTML/Rich Text"
                    />
                  </div>
                </div>
                <div
                  v-for="(language,prop) in modalData.languages"
                  :key="prop"
                >
                  <h4>{{ prop }}</h4>
                  <vue-editor
                    v-if="richText"
                    :id="prop"
                    v-model="modalData.languages[prop]"
                  />
                  <textarea
                    v-else
                    v-model="modalData.languages[prop]"
                  />
                </div>
              </div>
            </slot>
            <slot name="actions">
              <template>
                <va-button
                  @click="ok"
                  color="#cccccc"
                >
                  Cancel
                </va-button>
                <va-button
                  @click="saveEdit"
                  color="#85bb65"
                >
                  Save Key
                </va-button>
                <va-button
                  @click="deleteData"
                  :style="{ 'float': 'right' }"
                  color="#d20000"
                >
                  Delete
                </va-button>
              </template>
            </slot>
          </va-modal>
          <va-modal
            v-model="showCreateModal"
            :hide-default-actions="true"
            title="Editing Language"
            fixed-layout
          >
            <slot>
              <div
                class="modal"
                v-if="modalData"
              >
                <h3 class="underline">
                  <input
                    v-model="modalData.key"
                    placeholder="key"
                  >
                </h3>
                <div
                  v-for="(language,prop) in modalData.languages"
                  :key="prop"
                >
                  <h4>{{ prop }}</h4>
                  <textarea v-model="modalData.languages[prop]" />
                </div>
              </div>
            </slot>
            <slot name="actions">
              <template>
                <va-button
                  @click="ok"
                  color="#cccccc"
                >
                  Cancel
                </va-button>
                <va-button
                  @click="saveNew"
                  color="#85bb65"
                >
                  Save Key
                </va-button>
              </template>
            </slot>
          </va-modal>
        </p>
      </template>
      <slot>
        <va-button
          @click="openCreateModal()"
          color="#337CFF"
        >
          Create Key
        </va-button>
      </slot>
      <div>
        <va-card
          :title="'Translation Table'"
          class="xs12"
        >
          <div class="row align--center">
            <div class="flex xs12 md8">
              <va-input
                :value="term"
                :placeholder="'Search Term'"
                @input="search"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3 offset--md1">
              <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>

          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            @row-clicked="dataRowModal"
            :loading="loading"
            clickable
            hoverable
          />
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

// import { SemipolarSpinner } from 'epic-spinners'
import firebase from 'firebase';
import {debounce} from 'lodash';
import {VueEditor} from 'vue2-quill-editor';
const db = firebase.firestore();
export default {
  name: 'TranslationTable',
  components: {
    VueEditor,
  },
  data: function() {
    return {
      loading: true,
      languages: [],
      selectedLanguage: '',
      richText: false,
      tempLanguageData: [],
      languageData: {},
      languageDataArray: [],
      showModal: false,
      showCreateModal: false,
      tempElementName: '',
      tempElementLabel: '',
      englishWords: null,
      currentSort: 'key',
      currentSortDir: 'asc',
      modalData: null,
      term: '',
      perPage: '50',
      perPageOptions: ['10', '50', '100', '250', '500', '1000'],
    };
  },

  async created() {
    this.getData();
  },
  methods: {

    getData() {
      firebase.firestore()
          .collection('i18n')
          .get()

          .then((querySnapshot) => {
            this.languageDataArray = [];
            this.languageData = {};
            querySnapshot.forEach(async (doc) => {
              this.languages.push({
                id: doc.id,
              });
            });

            querySnapshot.forEach(async (doc) => {
              // this.languageData[doc.id][]

              const rowData = await doc.data();
              /* eslint-disable */
            for (const i in rowData) {
              if (!this.languageData[i]) {
                this.languageData[i] = {};
                for (const j in this.languages) {
                  this.languageData[i][this.languages[j].id] = '';
                }
              }
              
              this.languageData[i][doc.id] = rowData[i];
            }
          });
        }).then(() => {
          for(const i in this.languageData)
          {
            const languageObject = this.languageData[i];
            const pushableObject = {
              key: i
            };

            for (const j in this.languages) {
              pushableObject[this.languages[j].id] = languageObject[this.languages[j].id];
            }

            
            
            this.languageDataArray.push(pushableObject)
          }

          this.languageDataArray.sort((a,b)=>{
            if(a.key > b.key) return 1;
            if(a.key < b.key) return -1;
            return 0;
          })
          this.loading = false;
          
          this.sortedData();
        });
    },
    openCreateModal() {
      this.modalData = {
        key: '',
        languages: {},
      };
      this.languages.forEach((language) => {
        this.modalData.languages[language.id] = '';
      });
      this.showCreateModal = !this.showCreateModal;
    },
    saveNew() {
      const savePromises = []
      for (const i in this.modalData.languages) {
        const languageData = this.modalData.languages[i];
        const language = i;
        const dataKey = this.modalData.key;
        const languageDoc = db.collection('i18n').doc(language);
        const sendIt = {};
        sendIt[dataKey] = languageData;
        savePromises.push(languageDoc.set(sendIt, {merge: true}).then(() => {
          if (!this.languageData[this.modalData.key]) {
            this.languageData[this.modalData.key] = {};
          }
          this.languageData[this.modalData.key][language] = languageData;
          this.sortedData();
          this.ok();
        }))
      }
      Promise.all(savePromises).then(()=>{
        this.getData();
        this.$swal.fire({
          icon:'success',
          title: this.modalData.key + ' key created',
          timer: 1500
        })
      })
    },
    dataRowModal(dataRow, key) {
      if(dataRow.key)
      {
        key = dataRow.key
      }
      
      this.modalData = {
        key: key,
        languages: {},
      };
      this.languages.forEach((language) => {
        this.modalData.languages[language.id] = '';
        if (dataRow[language.id]) {
          this.modalData.languages[language.id] = dataRow[language.id];
        }
      });

      this.showModal = true;
    },
    deleteData: function() {
      console.log('delete');
      this.$swal.fire({
        title: 'Are you sure you want to delete <u>' + this.modalData.key + '</u>?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          /* eslint-disable */
          for (const i in this.modalData.languages) {
            const language = i;
            const dataKey = this.modalData.key;
            const languageDoc = db.collection('i18n').doc(language);
            const sendIt = {};
            sendIt[dataKey] = firebase.firestore.FieldValue.delete();
            languageDoc.update(sendIt).then(() => {
              delete this.languageData[dataKey];
            });
            this.ok();
          }
          this.$swal.fire('Item Deleted!', '', 'success');
          this.getData();
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      });
    },
    ok() {
      this.showModal = false;
      this.showCreateModal = false;
    },
    sort: function(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
      this.sortedData();
    },
    sortedData: function() {
      if (this.currentSort === 'key') {
        let ordered = Object.keys(this.languageData).reverse().reduce(
            (obj, key) => {
              obj[key] = this.languageData[key];
              return obj;
            },
            {},
        );
        if (this.currentSortDir === 'asc') {
          ordered = Object.keys(this.languageData).sort().reduce(
              (obj, key) => {
                obj[key] = this.languageData[key];
                return obj;
              },
              {},
          );
        }
        
        this.languageData = ordered;
      }
    },
    saveEdit() {
      const updates = [];
      for (const i in this.modalData.languages) {
        const languageData = this.modalData.languages[i];
        const language = i;
        const dataKey = this.modalData.key;
        const languageDoc = db.collection('i18n').doc(language);
        const sendIt = {};
        sendIt[dataKey] = languageData;
        updates.push(languageDoc.set(sendIt, {merge: true}).then(() => {
          this.languageData[dataKey][language] = languageData;
        }))
      }
      
      Promise.all(updates).then(()=>{
        this.getData()
          this.ok()
          this.$swal.fire({
            icon: 'success',
            title: this.modalData.key + ' updated',
            timer:1500
          })
        })
    },
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100),
  },
  watch: {

  },
  computed:{
    fields() {
      return [ {
        name: 'key',
        title: 'Key',
        sortField: 'key',
        width: '25%',
      }, {
        name: 'English',
        title: 'English',
        sortField:'English',
        width: '25%',
      }, {
        name: 'German',
        title: 'German',
        sortField:'German',
        width: '25%',
      }, {
        name: 'Spanish',
        title: 'Spanish',
        sortField:'Spanish',
        width: '25%',
      }];
    },
            filteredData() {
          
      if (!this.term || this.term.length < 1) {
        return this.languageDataArray;
      }

      return this.languageDataArray.filter((item) => {
        let returnKey = false;
        
        for(const l in item)
        {
          if(item[l].toLowerCase().indexOf(this.term.toLowerCase()) !== -1)
          {
            returnKey = true
          }
        }
        
        return returnKey;
      });
    },
  }

};
</script>

<style scoped>
table {
  display: none;
  background: #cccccc;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #dddddd;
  padding: 10px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #dddddd;
}

table tr td:hover {
  background-color: white;
  box-shadow: inset 0 0 10px #000000;
  cursor: pointer;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.underline {
  text-decoration: underline;
  margin-bottom: 1rem;
}

.modal {
  min-width: 600px;
  max-width: 1000px;
}

.modal textarea {
  width: 100%;
  min-height: 200px;
}

</style>
